import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Dialog } from '../../../../../../dataset/Dialog';
import { IDialog } from '../../../../../../shared/components/ui/modals/interfaces/IDialog';
import { Lesson } from '../../../../../../dataset/Lesson';

@Component({
  selector: 'app-lesson-done',
  templateUrl: './lesson-done.component.html',
  styleUrls: ['./lesson-done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonDoneComponent extends Dialog implements IDialog {
  closeModal(data?: unknown): void {
    this.resolve(data);
  }

  readonly lesson = input.required<Lesson>();
  readonly startDate = input.required<Date>();
}

import { Component, inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { DialogService } from './shared/components/ui/dialog/services/dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StorageContextService } from './context/storage-context/storage-context.service';
import { version } from '../environments/version';
import { filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from './core/services/google-analytics/google-analytics.service';
import { PixelsService } from './core/services/pixels/pixels.service';
import { SEOService } from './core/services/seo/seo.service';
import { LocaleService } from './core/services/locale/locale.service';
import { UtmTagsService } from './core/services/utm-tags-service/utm-tags-service';
import { NotificationService } from './shared/components/ui/notification/services/notification.service';
import { GlobalLoadingIndicatorService } from './services/global-loading-indicator/global-loading-indicator.service';
import { AnalyticsService } from './core/services/analytics/analytics.service';

export type Lang = 'en' | 'de' | 'es' | 'pt';

export function isLang(lang: Lang): lang is Lang {
  return lang === 'en' || lang === 'de' || lang === 'es' || lang === 'pt';
}

@UntilDestroy()
@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly globalLoader = inject(GlobalLoadingIndicatorService);
  readonly analytics = inject(AnalyticsService);

  cookieNoticeDisabled: boolean;
  loading: boolean;
  version = version;

  @ViewChild('dialogContainer', { read: ViewContainerRef, static: true })
  dialogContainerRef: ViewContainerRef;

  @ViewChild('notificationContainer', { read: ViewContainerRef, static: true })
  notificationContainerRef: ViewContainerRef;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private storageContext: StorageContextService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private pixels: PixelsService,
    private seoService: SEOService,
    public localeService: LocaleService,
    private utmTagsService: UtmTagsService
  ) {
    this.analytics.init().then();
  }

  private setFromParam(): void {
    const from = this.route.snapshot.queryParams['from'];
    if (from) {
      this.storageContext.setItem('from', from);
    }
  }

  private navigationStart(e: RouterEvent | Event): boolean {
    return e instanceof NavigationStart;
  }

  private navigationEnd(e: RouterEvent | Event): boolean {
    return e instanceof NavigationEnd;
  }

  private navigationOther(e: RouterEvent | Event): boolean {
    return (
      e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError
    );
  }

  ngOnInit(): void {
    this.localeService.initLocale();

    console.log(version);

    this.cookieNoticeDisabled = !!this.storageContext.getItem('cookie-notice');

    this.router.events
      .pipe(
        filter((e: RouterEvent | Event) => this.navigationStart(e) || this.navigationOther(e)),
        untilDestroyed(this)
      )
      .subscribe((event: RouterEvent | Event) => {
        if (this.navigationStart(event)) {
          this.loading = true;
        }

        if (this.navigationEnd(event)) {
          this.googleAnalyticsService.pageView(event);
          this.pixels.pageView();

          if (event instanceof NavigationEnd) {
            this.seoService.setTitleAndDescription(this.seoLocation(event?.url));
            // this.analytics.pageViewed().then();

            this.cookieNoticeDisabled =
              event.url.includes('profile/subscriptions') || this.cookieNoticeDisabled;

            if (event.url.includes('/quiz69')) {
              const segments = event.url.split('/');
              let lang: Lang | 'en-US' = segments?.[segments.length - 1]?.slice(0, 2) as Lang;

              lang = isLang(lang) ? lang : 'en-US';

              if (lang === 'en') {
                lang = 'en-US';
              }

              this.localeService.setLocale(lang);
              this.storageContext.setItem('lang', lang);
            }

            if (event.url.includes('prefix=quiz69')) {
              this.localeService.setLocale(this.storageContext.getItem('lang'));
            }

            this.utmTagsService.initUtmTagsConversion();
          }
        }

        if (this.navigationOther(event)) {
          this.setFromParam();
          this.loading = false;
        }
      });

    this.dialogService.registerViewContainer(this.dialogContainerRef);
    this.notificationService.registerViewContainer(this.notificationContainerRef);
  }

  disableCookieNotice(): void {
    this.cookieNoticeDisabled = true;
  }

  private seoLocation(url: string): string {
    if (url.includes('home') || url === '/') {
      return 'home';
    } else if (url.includes('packages')) {
      return 'packages';
    } else if (url.includes('challenges')) {
      return 'challenges';
    } else if (url.includes('gift')) {
      return 'giftCertificate';
    } else return 'default';
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Dialog } from '../../../../../dataset/Dialog';
import { IDialog } from '../interfaces/IDialog';
import { AppConfiguration } from '../../../../../core/services/configuration/app.configuration';
import { StorageContextService } from '../../../../../context/storage-context/storage-context.service';
import { SubscriptionContextService } from '../../../../../context/subscription-context/subscription-context.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardsApiService } from '../../../../../api/cards-api/cards-api.service';
import { PricesContextService } from '../../../../../context/prices-context/prices-context.service';
import { PackagesContextService } from '../../../../../context/packages-context/packages-context.service';

@UntilDestroy()
@Component({
  templateUrl: './sale-modal.component.html',
  styleUrls: ['./sale-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaleModalComponent extends Dialog implements IDialog, OnInit, OnDestroy {
  theme = this.appConfiguration.sale.theme;
  assets = this.appConfiguration.assets;

  currency: 'EUR' | 'USD';
  paymentSystem: 'paypal' | 'stripe';

  constructor(
    private appConfiguration: AppConfiguration,
    private storageContext: StorageContextService,
    private subscriptionContext: SubscriptionContextService,
    private pricesContext: PricesContextService,
    private cardsApiService: CardsApiService,
    private packagesContext: PackagesContextService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptionContext
      .get$()
      .pipe(untilDestroyed(this))
      .subscribe(subscriptions => {
        this.currency = (subscriptions?.[0]?.currency?.toUpperCase() ?? 'USD') as 'EUR' | 'USD';

        if (subscriptions) {
          this.paymentSystem = subscriptions[0]?.payment_system as 'stripe' | 'paypal';
        }

        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    if (this.cd) {
      this.cd.detach();
    }
  }

  closeModal(): void {
    this.resolve(false);
  }

  async buyNow(): Promise<void> {
    const userAuthorized = this.storageContext.getItem('access_token');
    if (userAuthorized) {
      if (this.isPaypal()) {
        const salePrice = this.pricesContext.get()?.find(price => price.special);

        this.cardsApiService.paypalUpdate(salePrice?.paypal_plan_id).subscribe(res => {
          this.closeModal();
          this.router.navigate(['/externalRedirect', { externalUrl: res.href }]);
        });
      } else {
        await this.router.navigateByUrl('/app/packages?action=upgrade');
      }
    } else {
      this.resolve(true);
      await this.router.navigateByUrl('/auth/sign-up/yearly');
    }
  }

  private isPaypal(): boolean {
    const boughtBundle = this.packagesContext.getBundles()?.find(b => b.subscription);

    if (boughtBundle) {
      return boughtBundle.subscription.payment_system === 'paypal';
    }

    return this.paymentSystem === 'paypal';
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import { User } from '../../../../../../dataset/User';
import { Lesson } from '../../../../../../dataset/Lesson';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsApiService } from '../../../../../../api/events-api/events-api.service';
import Player from '@vimeo/player';
import { FirstTimeClassWarningModalComponent } from '../../../../../../shared/components/ui/modals/first-time-class-warning-modal/first-time-class-warning-modal.component';
import { DialogService } from '../../../../../../shared/components/ui/dialog/services/dialog.service';
import { StorageContextService } from '../../../../../../context/storage-context/storage-context.service';
import dayjs from 'dayjs';
import { AppConfiguration } from '../../../../../../core/services/configuration/app.configuration';
import { isNotNullish, isNullish, Nullish } from '../../../../../../shared/helpers/ts.helpers';
import { ScheduledEvent } from '../../../../../../dataset/ScheduledEvent';
import { finalize } from 'rxjs/operators';
import { AnalyticsService } from '../../../../../../core/services/analytics/analytics.service';

export type VideoStates = 'play' | 'pause' | 'stop' | 'unknown';

// TODO: get from remote config
const RETRIEVE_EVENT_DELAY = 30 as const; // in seconds
const CAPTURE_EVENT_INTERVAL = 300 as const; // 5 min in seconds

@UntilDestroy()
@Component({
  selector: 'app-lesson-video',
  templateUrl: './lesson-video.component.html',
  styleUrls: ['./lesson-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonVideoComponent implements OnDestroy, AfterViewInit {
  readonly analytics = inject(AnalyticsService);

  @ViewChild('vimeo', { static: false }) vimeo: ElementRef<HTMLDivElement>;

  readonly user = input.required<Nullish<User>>(); // TODO: use user store
  readonly lesson = input.required<Lesson>();
  readonly eventId = input.required<number>();

  readonly completed = output<ScheduledEvent>();
  readonly error = output<Error>();

  sourceChangedVar = false;
  readonly startDate = signal(new Date());

  readonly timeLeft = signal('00:00');
  readonly firstTime = signal(true);

  readonly capturesCount = signal(0);

  readonly duration = signal(0);
  readonly watchedSecondsSet = signal<Set<number>>(new Set());

  readonly eventIsLoading = signal(false);
  readonly event = signal<Nullish<ScheduledEvent>>(null); // TODO: user event store

  readonly watchedSeconds = computed(() => {
    const seconds = [...this.watchedSecondsSet().values()].length;
    const eventSeconds = this.event()?.watched_seconds ?? 0;
    return seconds > eventSeconds ? seconds : eventSeconds;
  });
  readonly captureAfterSeconds = computed(
    () => (this.capturesCount() + 1) * CAPTURE_EVENT_INTERVAL
  );

  // readonly bonus = computed(() => this.watchedSeconds() / this.duration() > 0.8); // 80% to get the bonus

  readonly isTutorial = computed(() => +this.lesson().package === 18);
  readonly calories = computed(() => {
    const user = this.user();
    if (user) {
      const calories = this.calculateCalories(user.bmr, this.lesson().met, this.watchedSeconds());

      if (this.sourceChangedVar) {
        return calories > 0 ? calories - 1 : 0;
      }
      return calories;
    }
    return 0;
  });

  readonly showCalories = computed(() => {
    const user = this.user();
    if (isNullish(user)) return false;
    return (
      Boolean((user.growth && user.weight) || (user.growth_ft && user.weight_lb)) &&
      !this.firstTime() &&
      !this.isTutorial()
    );
  });

  constructor(
    private appConfig: AppConfiguration,
    private cd: ChangeDetectorRef,
    private eventsApiService: EventsApiService,
    private dialogService: DialogService,
    private storageContext: StorageContextService
  ) {
    // ONLY FOR DEBUG
    if (
      !this.appConfig.production &&
      this.storageContext.getItem('lesson_auto_complete') === 'true'
    ) {
      setTimeout(() => this.ended(), 3000);
    }

    // Load event effect
    effect(
      () => {
        if (isNotNullish(this.event()) || this.eventIsLoading() || this.isTutorial()) return;
        if (this.watchedSeconds() >= RETRIEVE_EVENT_DELAY) {
          this.loadEvent();
        }
      },
      { allowSignalWrites: true }
    );

    // Auto capture effect
    effect(
      () => {
        if (this.watchedSeconds() >= this.captureAfterSeconds()) {
          this.capturesCount.update(v => v + 1);
          this.capture();
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnDestroy(): void {
    this.capture();
    if (this.cd) {
      this.cd.detach();
    }
  }

  ngAfterViewInit(): void {
    const player = new Player(this.vimeo.nativeElement, {
      id: +this.lesson().vimeo_id,
      byline: false,
      title: false,
    });
    player.on('timeupdate', ({ seconds, duration }) => {
      this.duration.set(duration);
      this.saveWatchedSecond(seconds);
      this.timeLeft.set(dayjs.utc((duration - seconds) * 1000).format('mm:ss'));
    });
    player.on('ended', () => this.ended());
    player.on('finish', () => this.ended());
    player.on('finished', () => this.ended());
    player.on('play', async () => {
      if (this.firstTime()) {
        this.firstTime.set(false);
        await this.analytics.classWatchingStarted();
        await player.pause();
        await this.showFirstTimeClassWarning();
        await player.play();
      }
    });
    player.on('bufferstart', async () => {
      if (this.firstTime()) {
        this.firstTime.set(false);
        this.cd.detectChanges();
        await this.analytics.classWatchingStarted();
        player.pause();
        await this.showFirstTimeClassWarning();
        player.play();
      }
    });
    player.on('error', err => console.log(err));
    player.on('seeked', ({ seconds, duration }) => {
      if (duration - seconds === 0) {
        player.pause();
        this.ended();
      }
    });
  }

  loadEvent(): void {
    this.eventIsLoading.set(true);
    (this.eventId()
      ? this.eventsApiService.getEvent(this.eventId())
      : this.eventsApiService.getEventForLesson(this.lesson().id, this.startDate())
    )
      .pipe(
        untilDestroyed(this),
        finalize(() => this.eventIsLoading.set(false))
      )
      .subscribe(event => this.event.set(event));
  }

  ended(): void {
    this.capture(true);
  }

  capture(ended = false): void {
    const event = this.event();
    if (isNullish(event) || event.completed || this.isTutorial()) return;

    this.eventsApiService
      .capture(this.calories(), this.startDate(), event.id, this.watchedSeconds(), ended)
      .subscribe({
        next: event => {
          this.event.set(event);
          if (ended) {
            this.completed.emit(event);
          }
        },
        error: error => {
          this.error.emit(error);
        },
      });
  }

  private async showFirstTimeClassWarning(): Promise<void> {
    if (!this.storageContext.getItem('first-time-class-warning')) {
      const disableNotifications = await this.dialogService.open(
        FirstTimeClassWarningModalComponent
      );
      if (disableNotifications) {
        this.storageContext.setItem('first-time-class-warning', 'disabled');
      }
    }
  }

  private calculateCalories(mbr: number, met: number, seconds: number): number {
    return Math.round((mbr / 24) * met * (seconds / 3600));
  }

  private saveWatchedSecond(second: number) {
    const set = this.watchedSecondsSet();
    const rounded = Math.floor(second);
    if (set.has(rounded) || rounded === 0) return;
    set.add(Math.floor(second));
    this.watchedSecondsSet.set(new Set(set));
  }
}

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { StorageContextService } from '../../context/storage-context/storage-context.service';

@Injectable()
export class AccessTokenGuard {
  private readonly router = inject(Router);

  constructor(private storageContext: StorageContextService) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');

    if (apiKey) {
      this.storageContext.setItem('access_token', apiKey.replace(/\s/g, '+'));
      const query = route.queryParams;

      const { apiKey: _, ApiKey: __, ...queryParams } = query;
      const queryString = new URLSearchParams(Object.entries(queryParams));

      return this.router.parseUrl(window.location.pathname + '?' + queryString.toString());
    }

    return true;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Dialog } from '../../../../../dataset/Dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDialog } from '../interfaces/IDialog';
import { Lesson } from '../../../../../dataset/Lesson';
import { Package } from '../../../../../dataset/Package';
import { AddDateFilterComponent } from './add-date-filter/add-date-filter.component';
import { EventsApiService } from '../../../../../api/events-api/events-api.service';
import { catchError, map, tap } from 'rxjs/operators';
import { UserContextService } from '../../../../../context/user-context/user-context.service';
import { ScheduledEvent } from '../../../../../dataset/ScheduledEvent';
import { LessonsContextService } from '../../../../../context/lessons-context/lessons-context.service';
import { LessonsApiService } from '../../../../../api/lessons-api/lessons-api.service';
import { BuyContent } from '../../../../../dashboard/profile/profile-subscriptions/profile-subscriptions.component';
import { BuyModalComponent } from '../buy-modal/buy-modal.component';
import { Subscription } from '../../../../../dataset/Subscription';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { DialogService } from '../../dialog/services/dialog.service';
import { PackagesContextService } from '../../../../../context/packages-context/packages-context.service';
import { SubscribeService } from '../../../../../dashboard/packages/components/packages/services/subscribe.service';
import { combineLatest, lastValueFrom, Observable } from 'rxjs';
import { User } from '../../../../../dataset/User';
import { PricesContextService } from '../../../../../context/prices-context/prices-context.service';
import { Price } from '../../../../../dataset/Price';
import { TimeService } from '../../../../../core/services/time/time.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../../../../core/services/analytics/analytics.service';

@UntilDestroy()
@Component({
  templateUrl: './add-to-schedule-modal.component.html',
  styleUrls: ['./add-to-schedule-modal.component.scss'],
  providers: [SubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToScheduleModalComponent extends Dialog implements OnInit, OnDestroy, IDialog {
  readonly analytics = inject(AnalyticsService);

  @ViewChild(AddDateFilterComponent) filterComponent?: AddDateFilterComponent;

  prices: Price[] | null;
  initialized = false;

  packages: Package[];

  user: User | null;

  bundles: Package[] | null;
  superBundles: Package[] | null;
  specialBundle: Package | null;

  activeSuperBundle?: Package;
  activeSpecialBundle?: Package;
  activePackages: Package[] = [];

  lesson?: Lesson;
  lessons?: Lesson[];

  selectedPackage?: Package;

  get superMonthOffer(): Package | undefined {
    return this.superBundles?.find(b => this.packagesContext.isMonthlySubscription(b));
  }

  get superYearOffer(): Package | undefined {
    return this.superBundles?.find(b => this.packagesContext.isYearlySubscription(b));
  }

  get availableToAdd(): boolean {
    return !!(this.lesson && (this.selectedPackage?.is_bought || this.lesson.free));
  }

  constructor(
    private timeService: TimeService,
    private pricesContext: PricesContextService,
    private subscribeService: SubscribeService,
    private packagesContext: PackagesContextService,
    private dialogService: DialogService,
    private lessonsApiService: LessonsApiService,
    private lessonsContext: LessonsContextService,
    private userContext: UserContextService,
    private eventsApiService: EventsApiService,
    private cd: ChangeDetectorRef,
    private i18n: TranslateService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.packages = this.data.packages;

    combineLatest([
      this.pricesContext.get$(),
      this.userContext.get$(),
      this.packagesContext.getSuperBundles$(),
      this.packagesContext.getSpecialBundle$(),
      this.packagesContext.getBundles$(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([prices, user, superBundles, specialBundle, bundles]) => {
        this.prices = prices;
        this.user = user;
        this.superBundles = superBundles;
        this.specialBundle = specialBundle;
        this.bundles = bundles;

        this.activeSuperBundle = this.superBundles?.find(p => p.is_bought);
        this.activeSpecialBundle =
          this.specialBundle && this.specialBundle.is_bought ? this.specialBundle : undefined;
        this.activePackages = this.packages.filter(p => !!p.subscription);

        this.cd.detectChanges();
      });

    if (this.data.event) {
      await this.generateDataFromEvent(this.data.event);
    } else {
      this.commonGenerateData();
    }

    this.initialized = true;
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.cd) {
      this.cd.detach();
    }
  }

  closeModal(): void {
    this.resolve(false);
  }

  async getLessonsForPackage(pack?: Package): Promise<Lesson[] | undefined> {
    if (pack) {
      const lessonsContext = this.lessonsContext.get();

      if (lessonsContext && lessonsContext[pack.id]) {
        return Promise.resolve(lessonsContext[pack.id]);
      }

      return lastValueFrom(
        this.lessonsApiService.getLessonsForPackage(pack.id).pipe(
          tap(newLessons => {
            this.lessonsContext.next({ ...lessonsContext, [pack.id]: newLessons });
          })
        )
      );
    }

    return Promise.resolve(undefined);
  }

  async selectPackage(pack?: Package, update = false): Promise<void> {
    this.selectedPackage = pack ?? this.packages.find(p => p.id === this.lesson?.package);

    if (update) {
      this.lessons = await this.getLessonsForPackage(pack);
      this.cd.detectChanges();
    }
  }

  resetSelectedLesson(): void {
    this.lesson = undefined;
  }

  updateEvent(): void {
    if (this.filterComponent) {
      const date = this.timeService.processing(
        this.filterComponent.date,
        this.filterComponent.hour?.value,
        this.filterComponent.minute?.value,
        this.filterComponent.am?.value
      );

      if (this.lesson?.id && date) {
        this.createUpdateOperation(this.data.event?.id, date, this.lesson?.id, null, false)
          .pipe(untilDestroyed(this))
          .subscribe(event => {
            this.resolve(event);
          });
      }
    }
  }

  addToSchedule(): void {
    if (this.filterComponent) {
      const date = this.timeService.processing(
        this.filterComponent.date,
        this.filterComponent.hour?.value,
        this.filterComponent.minute?.value,
        this.filterComponent.am?.value
      );

      if (this.lesson?.id && date) {
        this.createOperation(date, this.lesson?.id, null, false)
          .pipe(untilDestroyed(this))
          .subscribe(event => {
            this.resolve(event);
          });
      }
    }
  }

  selectLesson(lesson: Lesson): void {
    this.lesson = lesson;
  }

  async subscribe(): Promise<void> {
    const { subscription } = await this.dialogService.open<BuyContent>(BuyModalComponent, {
      color: '#231F1F',
      user: this.user,
      monthlySubscription: this.superMonthOffer,
      yearlySubscription: this.superYearOffer || this.specialBundle,
      activeBundle: this.activeSuperBundle || this.activeSpecialBundle,
      currentSubscription: this.currentSubscription(),
      activePackages: this.activePackages,
      prices: this.prices,
    });

    if (subscription) {
      if (this.user?.hasCompletedTrial) {
        this.userContext.updateUser(this.user, false);
      } else if (this.user?.hasInactiveTrial) {
        this.userContext.updateUser(this.user, false, 'active');
      }
      await this.openSuccess(subscription);

      this.cd.detectChanges();
    }
  }

  async openSuccess(subscription: Subscription): Promise<void> {
    const message = this.subscribeService.messageForSuccess(subscription, this.user);

    await this.dialogService.open(InfoModalComponent, {
      title: 'Success',
      message,
    });
  }

  private createUpdateOperation(
    eventId: number,
    date: Date,
    lesson: number,
    rec: number | null,
    notify: boolean,
    force = false
  ): Observable<ScheduledEvent | unknown> {
    return this.eventsApiService.update(eventId, date, lesson, rec, notify).pipe(
      tap(() => {
        const user = this.userContext.get();
        this.userContext.updateUserStatus(user);
      }),
      //TODO fix this any
      catchError((err: Error): any => {
        if (err.message === 'Cannot create event. Time is busy.') {
          if (
            confirm(
              this.i18n.instant(
                'Another class is scheduled for this time. Replace it with a new class?'
              )
            )
          ) {
            force = true;

            return this.createUpdateOperation(eventId, date, lesson, rec, notify, force).pipe(
              //TODO fix this any
              map((event: any) => ({ ...event, isNew: true }))
            );
          }
        }
      })
    );
  }

  private createOperation(
    date: Date,
    lesson: number,
    rec: number | null,
    notify: boolean,
    force = false
  ): Observable<ScheduledEvent | unknown> {
    return this.eventsApiService.create(date, lesson, rec, notify, force).pipe(
      tap(() => {
        this.analytics.classAddedSchedule();
        const user = this.userContext.get();
        this.userContext.updateUserStatus(user);
      }),
      //TODO fix this any
      catchError((err: Error): any => {
        if (err.message === 'Cannot create event. Time is busy.') {
          if (
            confirm(
              this.i18n.instant(
                'Another class is scheduled for this time. Replace it with a new class?'
              )
            )
          ) {
            return this.createOperation(date, lesson, rec, notify, true).pipe(
              //TODO fix this any
              map((event: any) => ({ ...event, isNew: true }))
            );
          }
        }
      })
    );
  }

  private currentSubscription(): Package | null {
    return this.bundles?.find(p => p.is_bought) || null;
  }

  private async generateDataFromEvent(event: ScheduledEvent): Promise<void> {
    const pack = this.packages.find(p => p.id === event.lesson.package);
    await this.selectPackage(pack, true);

    this.lesson = this.lessons?.find(l => l.id === event.lesson.id);
  }

  private commonGenerateData(): void {
    this.lessons = this.data.lessons;
    this.lesson = this.data.lesson;

    this.data.getLessons ? this.selectPackage(this.packages[0], true) : this.selectPackage();
  }
}

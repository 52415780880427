import { ResolveFn, Router } from '@angular/router';

import { inject } from '@angular/core';
import { StorageContextService } from '../../context/storage-context/storage-context.service';

export const LicenseResolver: ResolveFn<unknown> = async route => {
  const storage = inject(StorageContextService);
  const router = inject(Router);

  const type = route.params['type'] || 'b2b';

  storage.setItem('license_type', route.params['type'] || 'b2b');
  storage.setItem('license', route.params['id']);

  if (storage.getItem('access_token')) {
    await router.navigate(['app', 'home']);
  } else if (type === 'wellhub') {
    await router.navigate(['auth', 'sign-up', 'yearly']);
  } else {
    await router.navigate(['auth', 'sign-in']);
  }

  return null;
};

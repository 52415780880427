import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { DictionaryService } from '../../../../../../services/dictionary/dictionary.service';
import { Router } from '@angular/router';
import { Dialog } from '../../../../../../dataset/Dialog';
import { IDialog } from '../../../../../../shared/components/ui/modals/interfaces/IDialog';
import { ScheduledEvent } from '../../../../../../dataset/ScheduledEvent';
import { ActualChallenge } from '../../../../../../dataset/ActualChallenge';

@Component({
  selector: 'app-lesson-challenge-complete',
  templateUrl: './lesson-challenge-complete.component.html',
  styleUrls: ['./lesson-challenge-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonChallengeCompleteComponent extends Dialog implements IDialog {
  private readonly router = inject(Router);
  public readonly dictionary = inject(DictionaryService);

  readonly event = input.required<ScheduledEvent>();
  readonly challenge = input.required<ActualChallenge>();
  readonly hasAccess = input.required<boolean>();
  readonly hasActiveSweat = input.required<boolean>();

  readonly isActiveSweat = computed(
    () => this.hasActiveSweat() && !this.hasAccess() && this.challenge().program.id === 38
  );

  readonly allEvents = computed(() =>
    this.challenge().events.map(e => (e.id === this.event().id ? this.event() : e))
  );

  // Metrics
  readonly calories = computed(() => this.allEvents().reduce((acc, cur) => acc + cur.calories, 0));

  readonly minutes = computed(() =>
    this.allEvents().reduce((acc, cur) => acc + Math.round((cur.watched_seconds ?? 0) / 60), 0)
  );

  readonly points = computed(
    () => this.allEvents().reduce((acc, cur) => acc + cur.bonus, 0) + this.minutes()
  );

  async goToChallenges(): Promise<void> {
    this.closeModal();
    await this.router.navigateByUrl('/app/challenges');
  }

  closeModal(data?: string): void {
    this.resolve(data ?? false);
  }
}

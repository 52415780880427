import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Dialog } from '../../../../../dataset/Dialog';
import { IDialog } from '../interfaces/IDialog';
import { LessonFeedbackValue } from '../../../../../dataset/LessonFeedback';
import { Value } from '../../../../../dataset/Value';
import { DictionaryService } from '../../../../../services/dictionary/dictionary.service';
import { ScheduledEvent } from '../../../../../dataset/ScheduledEvent';

@Component({
  selector: 'app-lesson-feedback',
  templateUrl: './lesson-feedback.component.html',
  styleUrls: ['./lesson-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonFeedbackComponent extends Dialog implements OnInit, IDialog {
  constructor(private dictionary: DictionaryService) {
    super();
  }

  ngOnInit(): void {
    this.event = this.data.event;
  }

  event: ScheduledEvent;

  closeModal(data?: unknown): void {
    this.resolve(data);
  }

  variants = this.dictionary.feedbackCases;
  variant: Value<LessonFeedbackValue>;

  select(variant: Value<LessonFeedbackValue>): void {
    this.variant = variant;
  }

  selected(variant: LessonFeedbackValue): boolean {
    return this.variant && this.variant.value === variant;
  }

  submit(): void {
    this.closeModal({ feedback: this.variant.value });
  }

  protected readonly Math = Math;
}

<app-dialog-container
  [state]="state"
  [backdropClose]="false"
  [padding]="'70px 94px'"
  [classNames]="'standard'"
  (close)="closeModal()">
  <div
    fxLayout="column"
    class="st-w-fill info"
    fxLayoutGap="10px">
    <div
      class="info-container st-h-fill"
      fxLayoutAlign="start center"
      fxLayoutAlign.sm.custom="center center"
      fxLayoutGap="80px"
      fxLayoutGap.sm.custom="40px"
      fxLayout="column">
      <div
        class="info-properties st-w-fill"
        fxLayout="row"
        fxLayoutGap="40px"
        fxLayoutGap.sm.custom="50px"
        fxLayoutAlign="center center">
        <div
          fxLayoutAlign="center center"
          fxLayoutGap="8px"
          fxLayout.xs.custom="column"
          fxLayoutGap.xs.custom="0"
          fxLayout="row">
          <svg
            width="24"
            height="24">
            <use xlink:href="#clock"></use>
          </svg>

          <h3
            class="st-h3"
            fxLayoutGap="8px"
            fxLayoutAlign="start center"
            fxLayout="row">
            <span>{{ Math.round((event.watched_seconds ?? 0) / 60) }}</span>
            <span class="properties-name">{{ 'min' | translate }}</span>
          </h3>
        </div>

        <div
          fxLayoutGap="8px"
          fxLayout.xs.custom="column"
          fxLayoutGap.xs.custom="0"
          fxLayoutAlign="center center"
          fxLayout="row">
          <svg
            width="24"
            height="24"
            class="st-stroke-black">
            <use xlink:href="#flame"></use>
          </svg>

          <h3
            class="st-h3"
            fxLayoutGap="8px"
            fxLayoutAlign="start center"
            fxLayout="row">
            <span>{{ event.calories }}</span>
            <span class="properties-name">{{ 'calories' | translate }}</span>
          </h3>
        </div>

        <div
          fxLayoutGap="8px"
          fxLayout.xs.custom="column"
          fxLayoutGap.xs.custom="0"
          fxLayoutAlign="center center"
          fxLayout="row">
          <svg
            width="24"
            height="24"
            class="star-2">
            <use xlink:href="#star-2"></use>
          </svg>

          <h3
            class="st-h3"
            fxLayoutGap="8px"
            fxLayoutAlign="start center"
            fxLayout="row">
            <span>{{ event.bonus }}</span>
            <span class="properties-name">{{ 'points' | translate }}</span>
          </h3>
        </div>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="start center"
        class="st-w-fill"
        fxLayoutGap.sm.custom="20px"
        fxLayoutGap="40px">
        <h2 class="st-h2 text-center">{{ 'Great! How did it feel?' | translate }}</h2>

        <div class="info-cases st-w-fill">
          <div
            class="info-case"
            fxFlex="0 0 50%"
            fxFlex.xs.custom="0 0 100%"
            *ngFor="let v of variants">
            <p
              role="presentation"
              class="info-case-wrapper st-p1"
              (click)="select(v)"
              [class.active]="selected(v.value)">
              {{ v.label ?? '' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <button
      class="st-btn st-btn-dark st-weight-700 st-w-fill"
      (click)="submit()"
      [disabled]="!variant">
      {{ 'Submit' | translate }}
    </button>
  </div>
</app-dialog-container>

<div
  class="vimeo-container"
  #vimeo>
  @if (!firstTime() && timeLeft() !== '00:00') {
    <div class="timeLeft">
      {{ timeLeft() }}
    </div>
  }
  @if (showCalories()) {
    <div class="calories flex items-center gap-2.5">
      <svg
        width="19"
        height="24"
        class="st-stroke-black">
        <use xlink:href="#flame"></use>
      </svg>
      <div class="calories-value">
        {{ calories() }}
      </div>
    </div>
  }
</div>
